import $ from "jquery";
declare let window: any;

//WEBフォントの非同期読み込み
$(function () {
  window.WebFontConfig = {
    google: { families: ['Oswald:500' , 'Noto+Sans+JP:400,500,700,900&display=swap&subset=japanese'] },
    active: function() {
      sessionStorage.fonts = true;
    }
  };
  (function() {
    const wf: any = document.createElement('script');
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    const s = document.getElementsByTagName('script')[0];
        s.parentNode?.insertBefore(wf, s);
  })();
});